<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="(event) =>
      $store.commit({
        type: 'updateSidebarVisible',
        value: event,
      })"
    class="custom-sidebar"
  >
    <CSidebarBrand>
      <CIcon
        custom-class-name="sidebar-brand-full"
        :icon="logoNegative"
        :height="35"
      />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
      />
    </CSidebarBrand>
    <div class="sidebar-content">
      <AppSidebarNav />
    </div>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { AppSidebarNav } from "./AppSidebarNav";
import { logoNegative } from "@/assets/brand/logo-negative";
import { sygnet } from "@/assets/brand/sygnet";
import { cilPeople } from "@coreui/icons";
import { useUserStore } from "@/plugins/store";
import axios from "axios";

export default {
  name: "AppSidebar",
  components: {
    AppSidebarNav,
  },
  data() {
    return {
      customers: 0,
    };
  },
  setup() {
    const userStore = useUserStore();
    const user = userStore.getUser;
    const store = useStore();
    return {
      user,
      cilPeople,
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    };
  },
  mounted() {
    // this.getCustomersUnits();
  },
  methods: {
    getCustomersUnits() {
      axios
        .get("v1/customers/all_units")
        .then((res) => {
          this.customers = res.data.length;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
  /* Asegura que el sidebar mantenga su comportamiento original */
  .custom-sidebar {
    height: 100vh; /* Ocupar toda la ventana del navegador */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Evitar que el scroll afecte el diseño principal */
    transition: width 0.3s; /* Suaviza el cambio al colapsar/desplegar */
  }

  /* Contenido desplazable */
  .sidebar-content {
    flex: 1;
    overflow-y: auto; /* Activa scroll para contenido interno */
    padding: 0.5rem; /* Opcional, ajusta margen interno */
  }

  /* Scroll personalizado */
  .sidebar-content {
    scrollbar-width: thin;
    scrollbar-color: #248377 transparent;
  }

  .sidebar-content::-webkit-scrollbar {
    width: 8px;
  }

  .sidebar-content::-webkit-scrollbar-track {
    background: transparent;
  }

  .sidebar-content::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #1FD4B5, #22C4B7, #23B0B9);
    border-radius: 4px;
  }

  .sidebar-content::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, #1FD4B5, #22C4B7);
  }

  /* Asegura que los íconos no se descuadren al colapsar */
  .custom-sidebar .sidebar-brand-narrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-sidebar .sidebar-brand-full {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>