export default [
  {

    component: 'CNavItem',
    name: 'Vehículos',
    to: '/vehicles',
    icon: 'cil-car-alt',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Asociados',
    to: '/associates',
    icon: 'cil-people',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Vehículos',
    to: '/vehicles/user',
    icon: 'cil-car-alt',
    require_admin: false,
    show_admin: false,
  },
  {
    component: 'CNavItem',
    name: 'Combustible',
    to: '/fuel',
    icon: 'cil-industry',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Ubicación',
    icon: 'cil-location-pin',
     to: '/location',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Gastos adicionales',
    to: '/additional_expenses',
    icon: 'cil-cash',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Bancos',
    to: '/banks',
    icon: 'cil-bank',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Canales',
    to: '/channels',
    icon: 'cil-line-weight',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Formas de compras',
    to: '/purchase_method',
    icon: 'cil-wallet',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavGroup',
    name: 'Reportes de Ventas',
    icon: 'cil-chart',
    require_admin: true,
    show_admin: true,
    items: [
      /*{
        component: 'CNavItem',
        name: 'Ventas por Usuario',
        to: '/graphics',
        icon: 'cil-chart-pie'
      },*/
      {
        component: 'CNavItem',
        name: 'Deudas por Usuario',
        to: '/debts_statistics',
        icon: 'cil-chart-pie'
      },
      {
        component: 'CNavItem',
        name: 'Ventas por Usuario',
        to: '/general_sale_statistics',
        icon: 'cil-chart-pie'
      },
      {
        component: 'CNavItem',
        name: 'Gráfico Mensual',
        to: '/monthly_graph',
        icon: 'cil-chart-pie'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Financiera',
    to: '/financial',
    icon: 'cil-wallet',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Comerciales',
    to: '/commercials',
    icon: 'cil-industry',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Objetivo',
    to: '/Goals',
    icon: 'cil-settings',
    require_admin: true,
    show_admin: true,
  },
  {
    component: 'CNavItem',
    name: 'Configuración',
    to: '/setting',
    icon: 'cil-settings',
    require_admin: true,
    show_admin: true,
  }
  /* {
    component: 'CNavItem',
    name: 'Tipo de Productos',
    to: '/product_types',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavItem',
    name: 'Tareas',
    to: '/tasks',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavItem',
    name: 'Recordatorio',
    to: '/reminders',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavItem',
    name: 'Prospectos',
    to: '/prospects',
    icon: 'cil-people',
  },
  {
    component: 'CNavItem',
    name: 'Revisióssn',
    to: '/revisions',
    icon: 'cil-puzzle'
  },
  {
    component: 'CSidebarNavDivider',
    _class: 'm-2'
  }, */
]