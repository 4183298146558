import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { useUserStore } from '@/plugins/store';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import DefaultLayout from '@/layouts/DefaultLayout'

const SellPage = () => import('@/views/sell/SellPage')
const SellUserPage = () => import('@/views/sell/SellUserPage')
const SellCreate = () => import('@/views/sell/SellCreate')
const SellEdit = () => import('@/views/sell/SellEdit')

const AssociateCreate = () => import('@/views/associates/AssociateCreate')
const AssociateEdit = () => import('@/views/associates/AssociateEdit')
const AssociatePage = () => import('@/views/associates/AssociatePage')

const BankCreate = () => import('@/views/bank/BankCreate')
const BankEdit = () => import('@/views/bank/BankEdit')
const BankPage = () => import('@/views/bank/BankPage')

const ChannelCreate = () => import('@/views/channel/ChannelCreate')
const ChannelEdit = () => import('@/views/channel/ChannelEdit')
const ChannelPage = () => import('@/views/channel/ChannelPage')

const FuelPage = () => import('@/views/fuel/FuelPage')
const FuelCreate = () => import('@/views/fuel/FuelCreate')
const FuelEdit = () => import('@/views/fuel/FuelEdit')

const GoalsPage = () => import('@/views/Goals/GoalsPage')

const AdditionalExpensesPage = () => import('@/views/additional_expenses/AdditionalExpensesPage')
const AdditionalExpensesCreate = () => import('@/views/additional_expenses/AdditionalExpensesCreate')
const AdditionalExpensesVehicleCreate = () => import('@/views/additional_expenses/AdditionalExpensesVehicleCreate')
const AdditionalExpensesEdit = () => import('@/views/additional_expenses/AdditionalExpensesEdit')

const LocationPage = () => import('@/views/location/LocationPage')
const LocationCreate = () => import('@/views/location/LocationCreate')
const LocationEdit = () => import('@/views/location/LocationEdit')

const PurchaseMethodPage = () => import('@/views/purchase_method/PurchaseMethodPage')
const PurchaseMethodCreate = () => import('@/views/purchase_method/PurchaseMethodCreate')
const PurchaseMethodEdit = () => import('@/views/purchase_method/PurchaseMethodEdit')

const GraphicsPage = () => import('@/views/graphics/GraphicsPage')
const MonthlyGraphPage = () => import('@/views/graphics/MonthlyGraphPage')
const GraphicsSalePage = () => import('@/views/graphics/GraphicsSalePage')
const GraphicsDebtsPage = () => import('@/views/graphics/GraphicsDebtsPage')

const FinancialPage = () => import('@/views/financial/FinancialPage')
const FinancialCreate = () => import('@/views/financial/FinancialCreate')
const FinancialEdit = () => import('@/views/financial/FinancialEdit')

const CommercialLocationPage = () => import('@/views/commercial_location/CommercialLocationPage')
const CommercialLocationCreate = () => import('@/views/commercial_location/CommercialLocationCreate')
const CommercialLocationEdit = () => import('@/views/commercial_location/CommercialLocationEdit')

const CommercialCreate = () => import('@/views/commercials/CommercialCreate')
const CommercialEdit = () => import('@/views/commercials/CommercialEdit')
const CommercialPage = () => import('@/views/commercials/CommercialPage')

const SettingPage = () => import('@/views/setting/SettingPage')

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/pages/login',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: 'associates',
        name: 'Asociados',
        meta: { label: 'Asociados', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Asociados ',
            meta: { label: 'Asociados', middleware: "admin" },
            component: AssociatePage
          },
          {
            path: 'create',
            name: 'Crear Asociado',
            meta: { label: 'Crear Asociado', middleware: "admin" },
            component: AssociateCreate
          },
          {
            path: ':id/edit',
            meta: { label: 'Editar Asociado', middleware: "admin" },
            name: 'Editar Asociado',
            component: AssociateEdit
          }
        ]
      },
      {
        path: 'banks',
        name: 'Bancos',
        meta: { label: 'Bancos', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Bancos ',
            meta: { label: 'Bancos', middleware: "admin" },
            component: BankPage
          },
          {
            path: 'create',
            name: 'Crear Banco',
            meta: { label: 'Crear Banco', middleware: "admin" },
            component: BankCreate
          },
          {
            path: ':id/edit',
            meta: { label: 'Editar Banco', middleware: "admin" },
            name: 'Editar Banco',
            component: BankEdit
          }
        ]
      },
       {
        path: 'channels',
        name: 'Canales',
        meta: { label: 'Canales', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Canales ',
            meta: { label: 'Canales', middleware: "admin" },
            component: ChannelPage
          },
          {
            path: 'create',
            name: 'Crear Canal',
            meta: { label: 'Crear Canal', middleware: "admin" },
            component: ChannelCreate
          },
          {
            path: ':id/edit',
            meta: { label: 'Editar Canal', middleware: "admin" },
            name: 'Editar Canal',
            component: ChannelEdit
          }
        ]
      },
      {
        path: 'vehicles',
        name: 'Vehículos  ',
        meta: { label: 'Vehículos', middleware: "user" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Vehículos',
            meta: { label: 'Vehículos', middleware: "admin" },
            component: SellPage
          },
          {
            path: 'user',
            name: 'Vehículos ',
            meta: { label: 'Vehículos ', middleware: "user" },
            component: SellUserPage
          },
          {
            path: 'create',
            name: 'Crear Vehículo',
            meta: { label: 'Vehículo Vehículo', middleware: "user" },
            component: SellCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Vehículo',
            meta: { label: 'Editar Vehículo', middleware: "user" },
            component: SellEdit
            
          }
        ]
      },
      {
        path: 'fuel',
        name: 'Combustible',
        meta: { label: 'Combustible', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Lista Combustible',
            meta: { label: 'Combustible', middleware: "admin" },
            component: FuelPage
          },
          {
            path: 'create',
            name: 'Crear Combustible',
            meta: { label: 'Crear Combustible', middleware: "admin" },
            component: FuelCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Combustible',
            meta: { label: 'Editar Combustible', middleware: "admin" },
            component: FuelEdit
          }
        ]
      },

      {
        path: 'goals',
        name: 'Objetivo',
        meta: { label: 'Objetivo', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Lista Objetivo',
            meta: { label: 'Objetivo', middleware: "admin" },
            component: GoalsPage
          },
        ]
      },
      
      {
        path: 'financial',
        name: 'Financiera',
        meta: { label: 'Financiera', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Lista Financiera',
            meta: { label: 'Financiera', middleware: "admin" },
            component: FinancialPage
          },
          {
            path: 'create',
            name: 'Crear Financiera',
            meta: { label: 'Crear Financiera', middleware: "admin" },
            component: FinancialCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Financiera',
            meta: { label: 'Editar Financiera', middleware: "admin" },
            component: FinancialEdit
          }
        ]
      },

      {
        path: 'commercials',
        name: 'Comerciales',
        meta: { label: 'Ubicacion Comercial ', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Comerciales ',
            meta: { label: 'Comerciales', middleware: "admin" },
            component: CommercialPage
          },
          {
            path: 'create',
            name: 'Crear Comercial',
            meta: { label: 'Crear Comercial', middleware: "admin" },
            component: CommercialCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Comercial',
            meta: { label: 'Editar Comercial', middleware: "admin" },
            component: CommercialEdit
          },
        ]
      },
      {
        path: 'commercial_location',
        name: 'Ubicacion Comercial',
        meta: { label: 'Ubicacion Comercial ', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Ubicacion Comercial ',
            meta: { label: 'Ubicacion Comercial ', middleware: "admin" },
            component: CommercialLocationPage
          },
          {
            path: 'create',
            name: 'Crear Ubicacion Comercial',
            meta: { label: 'Crear Ubicacion Comercial', middleware: "admin" },
            component: CommercialLocationCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Ubicacion Comercial',
            meta: { label: 'Editar Ubicacion Comercial', middleware: "admin" },
            component: CommercialLocationEdit
          },
        ]
      },
      {
        path: 'location',
        name: 'Ubicación',
        meta: { label: 'Ubicación', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Lista Ubicación',
            meta: { label: 'Ubicación', middleware: "admin" },
            component: LocationPage
          },
          {
            path: 'create',
            name: 'Crear Ubicación',
            meta: { label: 'Crear Ubicación', middleware: "admin" },
            component: LocationCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Ubicación',
            meta: { label: 'Editar Ubicación', middleware: "admin" },
            component: LocationEdit
          }
        ]
      },
      {
        path: 'additional_expenses',
        name: 'Gastos Adicionales',
        meta: { label: 'Additional_expenses', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Lista GastosAdicionales',
            meta: { label: 'Additional Expenses', middleware: "admin" },
            component: AdditionalExpensesPage
          },
          {
            path: 'create',
            name: 'Crear Gastos Adicional',
            meta: { label: 'Crear Additional Expenses', middleware: "user" },
            component: AdditionalExpensesCreate
          },
          {
            path: 'create/:vehicle_transaction_id/vehicle',
            name: 'Agregar Gastos Adicional a Vehiculo',
            meta: { label: 'Crear Additional Expenses', middleware: "user" },
            component: AdditionalExpensesVehicleCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Gastos Adicionales',
            meta: { label: 'Editar Additional Expenses', middleware: "admin" },
            component: AdditionalExpensesEdit
          }
        ]
      },
      {
        path: 'purchase_method',
        name: 'Formas De Compras',
        meta: { label: 'Purchase_method', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Lista Formas De Compras',
            meta: { label: 'Purchase_method', middleware: "admin" },
            component: PurchaseMethodPage
          },
          {
            path: 'create',
            name: 'Crear Forma de Compra',
            meta: { label: 'Crear Purchase Method', middleware: "admin" },
            component: PurchaseMethodCreate
          },
          {
            path: 'edit/:id',
            name: 'Editar Forma de Compra',
            meta: { label: 'Editar Purchase Method', middleware: "admin" },
            component: PurchaseMethodEdit
          }
        ]
      },
      {
        path: 'setting',
        name: 'Configuración',
        meta: { label: 'setting', middleware: "user" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Configuración ',
            meta: { label: 'Configuración', middleware: "user" },
            component: SettingPage
          }
        ]
      },
      {
        path: 'debts_statistics',
        name: 'EstadisticaDeudas',
        meta: { label: 'Estadistica Deudas', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'VistaEstadisticaDeudas',
            meta: { label: 'Estadistica Deudas', middleware: "admin" },
            component: GraphicsDebtsPage
          },
        ]
      },
      {
        path: 'graphics',
        name: 'EstadisticaGeneral',
        meta: { label: 'Estadistica', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'VistaEstadisticaGeneral',
            meta: { label: 'Estadistica', middleware: "admin" },
            component: GraphicsPage
          },
        ]
      },
      {
        path: 'monthly_graph',
        name: 'EstadisticaMensual',
        meta: { label: 'Estadistica Mensual', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'VistaEstadisticaMensual',
            meta: { label: 'Estadistica Mensual', middleware: "admin" },
            component: MonthlyGraphPage
          },
        ]
      },
      {
        path: 'general_sale_statistics',
        name: 'EstadisticaVentas',
        meta: { label: 'Estadistica de Ventas', middleware: "admin" },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'VistaEstadisticaVentas',
            meta: { label: 'Estadistica de Ventas', middleware: "admin" },
            component: GraphicsSalePage
          },
        ]
      },
    ],
  },
  

  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})



router.beforeEach((to, from, next) => {
  
  const userStore = useUserStore();
  const user = userStore.getUser
  
  if (to?.meta?.middleware === "admin") {
      if (!user?.is_admin) {
           Toast.fire({
            icon: 'error',
            title: 'No tiene autorización',
          })
          router.go(-1)
          return // Añade esta línea para prevenir llamadas múltiples a next()
      }
      next()
  }

  next()
})

export default router
