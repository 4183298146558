import { defineStore } from "pinia";
import { useLocalStorage } from '@vueuse/core'
import jwtToken from "@/plugins/jwt/jwt-token.js";
import axios from 'axios'

export const useUserStore = defineStore("user", {
  state: () => ({
    user: useLocalStorage('user', null),
  }),
  actions: {
    async fetchUser() {
      const res = await axios.post("/user");
      const user = JSON.stringify(res.data.user)
      this.user = user;
    },
    async signUp(first_name, last_name, email, password, password_confirmation) {
      const res = await axios.post("api/auth/register",{
        first_name,
        last_name,
        email,
        password, 
        password_confirmation,
        device_name: navigator.userAgent
      });
     
      res.data.user ? this.user = JSON.stringify(res.data.user) : null
      res.data.access_token ? jwtToken.setToken(res.data.access_token) : null
     
      return res
    },
    async signIn(email, password) {
      const res = await axios.post("api/auth/login",{
         email, 
         password,
         device_name: navigator.userAgent
      });

      res.data.user ? this.user = JSON.stringify(res.data.user) : null
      res.data.access_token ? jwtToken.setToken(res.data.access_token) : null
      return res
    },
    async editProfile(data) {
      const res = await axios.post("api/auth/update",data);
      res.data ? this.user = JSON.stringify(res.data.user) : null
      return res
    },
    async logout() {
      const res = await axios.post("api/auth/logout");
      this.user = null
      jwtToken.removeToken()
      return res
    },
  },
  getters: {
    getUser(state) {
      return JSON.parse(state.user)
    },
  },
});
