<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="baseURL+user.image" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        {{user?.first_name+' '+user?.last_name }}
      </CDropdownHeader>
      <!--<CDropdownDivider/>-->
      <CDropdownItem @click="logout"> 
        <CIcon icon="cil-lock-locked" /> 
        Cerrar sesión 
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import axios from 'axios';
import { useUserStore } from '@/plugins/store';
import $ from 'jquery'

export default {
  name: 'AppHeaderDropdownAccnt',
  data () {
    return { 
      avatar: avatar,
      user : null,
      baseURL: null
    }
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  created() {
    this.user = this.userStore.getUser
    this.baseURL = axios.defaults.baseURL;
  },
  mounted(){
    $('[placement="bottom-end"]').removeAttr('href')
  },
  methods: {
    logout(){
      this.userStore.logout()
      .finally(() => {
        location.href= '#/pages/login'
      })
    },
  } 
}
</script>
